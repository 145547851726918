import(/* webpackMode: "eager" */ "/app/node_modules/@akinon/next/components/image.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/@akinon/next/components/lazy-component.tsx");
import(/* webpackMode: "eager" */ "/app/node_modules/@akinon/next/utils/get-currency.ts");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/app/src/app/[commerce]/[locale]/[currency]/widget-wrapper.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/band-slider.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/brand-logo.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/button.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/description.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/dynamic-slider.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/home-hero-slider.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/index.ts");
import(/* webpackMode: "eager" */ "/app/src/components/link.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/widget-slider.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/breadcrumb.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/product-item/templates/makeup-item.tsx");
import(/* webpackMode: "eager" */ "/app/src/views/product-pointer-banner-item.tsx");
import(/* webpackMode: "eager" */ "/app/src/widgets/home-iframe-loader.tsx");
import(/* webpackMode: "eager" */ "/app/src/widgets/home-single-banner.tsx");
import(/* webpackMode: "eager" */ "/app/src/widgets/new-arrivals-recommendation.tsx");
import(/* webpackMode: "eager" */ "/app/src/widgets/recommendation-content.tsx");
import(/* webpackMode: "eager" */ "/app/src/widgets/transparent-background-items.tsx")