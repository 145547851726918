'use client';

import clsx from 'clsx';
import { ReactNode } from 'react';
import { WidgetOrderItemType } from 'types';
import { useMediaQuery } from '@akinon/next/hooks';
import { useEffect, useState } from 'react';

export const WidgetWrapper = ({
  children,
  widget
}: {
  children: ReactNode;
  widget: WidgetOrderItemType;
}) => {
  const matches = useMediaQuery('(min-width: 768px)');

  const [matchesStatus, setMatchesStatus] = useState(false);

  useEffect(() => {
    setMatchesStatus(matches);
  }, [matches]);

  const style = {};

  if (!matchesStatus) {
    if (widget.value.padding_top) {
      style['paddingTop'] = `${widget.value.padding_top}`;
    }

    if (widget.value.padding_bottom) {
      style['paddingBottom'] = `${widget.value.padding_bottom}`;
    }
  } else {
    if (widget.value.desktop_padding_top) {
      style['paddingTop'] = `${widget.value.desktop_padding_top}`;
    }

    if (widget.value.desktop_padding_bottom) {
      style['paddingBottom'] = `${widget.value.desktop_padding_bottom}`;
    }
  }

  return (
    <div
      style={style}
      className={clsx(widget.value.is_contained === 'yes' && 'container')}
    >
      {children}
    </div>
  );
};

export default WidgetWrapper;
